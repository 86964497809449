<template>
  <div id="my-account">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">My Account</div>
        <div class="actions-buttons"></div>
      </el-header>
    </portal>
    <div class="table-container">
      <div class="view-info-account">
        <div class="my-account">
          <div class="title">Profile</div>
          <div class="line"></div>
          <div class="upload-photo">
            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <el-row :gutter="24">
            <el-col :span="12" class="input-container">
              <div class="text">First Name</div>
              <el-input v-model="form.FirstName" placeholder="..."></el-input>
            </el-col>
            <el-col :span="12" class="input-container">
              <div class="text">Last Name</div>
              <el-input v-model="form.LastName" placeholder="..."></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12" class="input-container">
              <div class="text">Job Title</div>
              <el-input v-model="form.JobTitle" placeholder="..."></el-input>
            </el-col>
            <el-col :span="12" class="input-container">
              <div class="text">Contact Number</div>
              <el-input v-model="form.PhoneNumber" placeholder="+90 554 554 54 54"></el-input>
            </el-col>
          </el-row>
          <div class="line"></div>
          <div class="buttons-group">
            <el-button class="btn" type="primary" @click="saveAccount"><i class="icon-floppy-disk-solid"></i>Save</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        JobTitle: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
      },
      imageUrl: "",
    };
  },
  methods: {
    async saveAccount() {
      var payload = {
        JobTitle: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
      };
      var res = await this.$client.post("/Account/UpdateProfile", payload);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        this.getProject();
      }
    },
    develop() {
      this.$message.info("Bu özellik geliştirme aşamasındadır...");
      return;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("Avatar picture must be JPG format!");
      }
      if (!isLt2M) {
        this.$message.error("Avatar picture size can not exceed 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style></style>
